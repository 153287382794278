import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h2>ΔΕΝ ΒΡΕΘΗΚΕ!</h2>
    <p>
      Αυτή η σελίδα δεν βρέθηκε. Όπως ακριβώς η τσίπα, η ντροπή και η ηθική στους οδηγούς των οχημάτων αυτής της
      ιστοσελίδας.
    </p>
  </Layout>
)

export default NotFoundPage
